<template>
  <div class="kecheng">
    <kind-choose-view :show-school="true" :show-kc="false" :type="15" :schoole="schoole" :kind="kind" :entrants-type="3"
      :zy-id="zyId" @isOk="isOk" />
    <!-- 内容 -->
    <div class="kecent">
      <!-- <router-view /> -->
      <div v-for="(item, index) in list" :key="index" class="kecents" @click="imgxq(index, item)">
        <img class="item-img" :src="item.img">
        <div class="clicks_num">
          <div v-if="item.productLine == 2" class="productLine">精品</div>
          <div class="num">{{ item.clicks }}人气值</div>
        </div>
        <div class="kecentsCen">
          <div class="kecentsCenTop elp">{{ item.tiitle }}</div>
          <div class="kecentsCenBtm">
            <div class="curForms">
              #
              <span v-for="(curForms, curindex) in item.curForms" :key="curindex">
                {{ curindex != 0 ? "·" : "" }}{{ curForms }}
              </span>
            </div>
            <div class="colleges-price">
              <span class="price-icon">¥ </span>{{ item.price }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 暂无课程 -->
    <div style="margin: 0 auto;" v-if="!list || list.length == 0">
      <el-empty description="暂无数据"></el-empty>
    </div>
    <!-- <div v-if="!list || list.length == 0" class="nothing">
  
      <div class="nothing-text">暂无内容哦~</div>
    </div> -->
    <div style="
        display: flex;
        justify-content: center;
        align-items: center;
        width: 30%;
        margin: 40px auto;
        margin-bottom: 80px;
      ">
      <el-pagination class="pagination colleges-pagination" :total="total" :current-page="pageNum"
        :page-sizes="[12, 24, 28, 32]" :page-size="pageSize" :pager-count="5"
        layout="total, sizes, prev, pager, next, jumper" @current-change="handleCurrentChange"
        @size-change="handleSizeChange" />
    </div>
  </div>
</template>
<script>
import Vue from 'vue'
import KindChooseView from '../../components/kindChooseViewktypevipSeventh.vue'
import { getSurfaceList, selectNewPageListToIndex } from '@/api/home'
import { getDomain } from '@/api/cookies'

export default {
  components: { KindChooseView },

  data() {
    return {
      imgList: {},
      list: {},
      search: {},
      pageSize: 12,
      pageNum: 1,
      total: 0,
      kind: null,
      zyId: null,
      schoole: null,
      domain: null,
      timer: null,
      switch: false,
      loading: null
    }
  },
  async beforeDestroy() {
    this.loading.close()
  },
  async created() {
    this.domain = getDomain()
    await getSurfaceList(4, undefined, this.domain.entrantsType).then((res) => {
      if (res.code == 0) {
        if (res.msg.length > 0) {
          this.imgList = res.msg[0]
        } else {
          this.imgList = {}
        }
      }
    })
    if (this.$route.query.kind) {
      this.kind = await Number(this.$route.query.kind)
      this.search.kind = await Number(this.$route.query.kind)
    }
    if (this.$route.query.zId) {
      this.zyId = await Number(this.$route.query.zId)
      this.search.zyId = await Number(this.$route.query.zId)
    }
    if (this.$route.query.schoole) {
      this.schoole = await Number(this.$route.query.schoole)
      this.search.schoole = await Number(this.$route.query.schoole)
    }
    await this.getList()
  },
  methods: {
    // 获取知识套餐列表
    getList() {
      this.loading = this.$loading({
        lock: true,
        text: '加载中',
        spinner: '',
        background: 'rgba(0, 0, 0, 0.2)'
      })
      selectNewPageListToIndex(
        this.pageSize,
        this.pageNum,
        this.search.schoolId,
        this.search.kind,
        this.search.zyId,
        this.search.type,
        this.search.productLine,
        // this.search.kind == 1 ? '专升本' : this.search.stageName,
        this.search.areaId == 1 ? undefined : this.search.areaId,
        this.search.name ? this.search.name : undefined

      ).then((res) => {
        for (const item of res.rows) {
          if (item.curForms) {
            item.curForms = item.curForms.split(',')
          }
        }
        this.list = res.rows
        this.total = res.total
        if (!this.switch) {
          this.switch = true
        }
        this.loading.close()
      }).catch(() => {
        this.loading.close()
      })
    },

    handleSizeChange(val) {
      this.pageSize = val
      this.getList()
    },
    handleCurrentChange(val) {
      this.pageNum = val
      this.getList()
    },
    // 筛选
    isOk(val) {

      if (this.switch) {
        this.search = val
        this.pageNum = 1
        this.getList()
      } else {
        clearTimeout(this.timer)
        this.timer = setTimeout(() => {
          this.search = val
          this.pageNum = 1
          this.getList()
        }, 1000)
      }
    },

    // 点击图片跳转到详情页
    imgxq(index) {
      /* this.$router.push({
        name: "知识套餐课详情",
        query: {
          id: this.list[index].id,
          tenantid: this.search.tenantid,
          type: this.search.type,
          entrantsPrice: this.list[index].entrantsPrice,
          jgPrice: this.list[index].jgPrice,
        },
      }); */
      const routeUrl = this.$router.resolve({
        name: '知识套餐课详情',
        query: {
          id: this.list[index].id,
          tenantid: this.search.tenantid,
          type: this.search.type,
          entrantsPrice: this.list[index].entrantsPrice,
          jgPrice: this.list[index].jgPrice
        }
      })
      window.open(routeUrl.href, '_blank')
    }
  }
}
</script>
<style lang="less" scoped>
.kecheng {
  width: 100%;
  height: 100%;
  background: #f5f7fa;
  overflow: hidden;

  // 内容
  .kecent {
    width: calc(1200px + 24px);
    height: auto;
    margin: 0px calc(50% - 600px);
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    background: #f5f7fa;

    .kecents {
      width: calc(25% - 24px);
      cursor: pointer;
      margin: 0px 24px 32px 0px;
      overflow: hidden;
      position: relative;

      .clicks_num {
        position: absolute;
        top: 8px;
        left: 8px;
        display: flex;

        .productLine {
          width: 36px;
          height: 20px;
          background: linear-gradient(132deg, #ff9051 0%, #ff5e51 100%);
          border-radius: 3px 3px 3px 3px;
          opacity: 1;
          font-size: 12px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #ffffff;
          line-height: 20px;
          text-align: center;
        }

        .num {
          font-size: 12px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #ffffff;
          line-height: 20px;
          height: 20px;
          background: rgba(0, 0, 0, 0.5);
          border-radius: 4px 4px 4px 4px;
          padding: 0px 4px;
        }
      }

      img {
        width: 100%;
        height: 161px;
        border-radius: 8px 8px 0px 0px;
        opacity: 1;
      }

      .kecentsCen {
        width: 100%;
        margin: auto;

        .kecentsCenTop {
          height: 24px;
          font-size: 16px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #333333;
          line-height: 24px;
          margin-top: 12px;
        }

        .kecentsCenBtm {
          margin-top: 18px;
          display: flex;
          justify-content: space-between;

          .curForms {
            font-size: 12px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #b8681e;
            line-height: 12px;
          }
        }
      }
    }
  }
}

.isNull {
  width: 1200px;
  margin: 0px auto;
}
</style>
